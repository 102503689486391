$( document ).ready(function()
{
    activateWebshopEvents();
});

/**
 * Activate webshop events
 */
function activateWebshopEvents()
{
    //Remove click effect from coupon buttons
    $('[data-pdev-action="ws-add-coupon"], [data-pdev-action="ws-remove-coupon"]').off('click');

    //Add coupon code
    //Selector: ws-add-coupon
    $('[data-pdev-action="ws-add-coupon"]').off();
    $('[data-pdev-action="ws-add-coupon"]').on('click', async function()
    {
        const res = await addCouponCart();
        returnNotification('', res.msg, (res.error == 1 ? 'warning' : 'success'));
        await setCartTotals();
        if(parseInt(res.error) === 0) {
            //Get coupon element and set active coupon
            const couponElement = $('[data-pdev-active-coupon]');
            if(couponElement[0].tagName.toLowerCase() === 'input') {
                couponElement.val(res.code);
            }
            else {
                couponElement.html(res.code);
            }
            //Show active coupon content
            $('[data-pdev-active-coupon-show]').show();
        }
    });

    //Remove coupon code
    //Selector: ws-remove-coupon
    $('[data-pdev-action="ws-remove-coupon"]').off();
    $('[data-pdev-action="ws-remove-coupon"]').on('click', async function()
    {
        await removeCouponCart();
        await setCartTotals();
        $('[data-pdev-active-coupon-show]').hide();
    });

    //Delete item from cart
    //Selector: ws-delete-cart
    //Needs attributes: data-pdev-cart-key, data-pdev-pid
    $('[data-pdev-action="ws-delete-cart"]').off();
    $('[data-pdev-action="ws-delete-cart"]').on('click', async function()
    {
        //Get cart key and product id
        const cart_key = $(this).attr("data-pdev-cart-key");
        const pid = $(this).attr("data-pdev-pid");

        //Delete from cart
        const res = await deleteFromCart(cart_key, pid);
        if(res.error) {
            returnNotification('', res.msg, 'warning');
            return false;
        }

        //Get optional parameters & refresh cart content
        const parameters = (typeof pdev_shopping_cart_content_parameters !== 'undefined' ? pdev_shopping_cart_content_parameters : {});
        await cartContent(parameters);

        //Set rest of cart totals
        await setCartTotals();
    });

    //Update cart item amount
    //Selector: ws-cart-amount
    //Needs attributes: data-pdev-cart-key, data-pdev-pid
    $('input[data-pdev-action="ws-cart-amount"]').off();
    $('input[data-pdev-action="ws-cart-amount"]').on('change', async function()
    {
        const cart_key = $(this).attr("data-pdev-cart-key");
        const pid = $(this).attr("data-pdev-pid");
        const res = await updateCartAmount(cart_key, pid, this.value);
        if(parseInt(res.error) === 1) {
            returnNotification('', res.msg, 'danger');
        }
        else {
            //amount 0 = delete row
            if(parseInt(this.value) === 0) {
                $('[data-pdev-cart-row="' + cart_key + '"]').remove();
            }
            //else update row total price
            else {
                $('[data-pdev-cart-row-total="' + cart_key + '"]').html(moneyFormat(res.rowPrice));
            }
            await setCartTotals();
        }
    });

    //Add item to cart
    //Selector: ws-add-cart
    //Can use attributes: data-pdev-cart-amount, data-pdev-cart-note
    $('a[data-pdev-action="ws-add-cart"]').off();
    $('a[data-pdev-action="ws-add-cart"]').on('click', async function()
    {
        const pid = $(this).attr("data-pdev-pid");
        const res = await addToCart(pid);
        const response = $('*[data-pdev-cart-response=' + pid + ']');
        if(!response.length) {
            returnNotification('', res.msg, (res.error == 1 ? 'warning' : 'success'));
        }
        else {
            response.html(res.msg);
        }
        let totals = await setCartTotals();

        //Check if response function exists and execute it
        if(window.PDEV_ws_add_cart) {
            PDEV_ws_add_cart(totals);
        }
    });

    //Change shipping method
    //Selector: ws-shipping-method
    $('*[data-pdev-ws-shipping-method]').on('change', async function()
    {
        await changeShippingMethod(getShippingMethod());
        await setCartTotals();
    });

    //todo:  done -----------------------------------------------------------------------------








    //Create order
    $('*[data-pdev-action="ws-create-order"]').off();
    $('*[data-pdev-action="ws-create-order"]').on('click', function()
    {
        createOrder();
    });

    //Create order payment
    $('*[data-pdev-action="ot-create-payment"]').off();
    $('*[data-pdev-action="ot-create-payment"]').on('click', function()
    {
        const track_id = $(this).attr("data-pdev-track-id");
        createOrderPayment(track_id, true);
    });






    //Todo: not used for ToOrder, redo later

    //Change main product image
    //Selector: set-product-image
    //Needs element: #pdev-product-main-image
    $('img[data-pdev-action="set-product-image"]').off();
    $('img[data-pdev-action="set-product-image"]').on('click', function()
    {
        const src = $(this).attr("data-image");
        const image = $('#pdev-product-main-image');
        image.attr("src",src);
    });

    //Show / hide different shipping
    $('input[data-pdev-action="ws-different-shipping"]').off();
    $('input[data-pdev-action="ws-different-shipping"]').on('click', function()
    {
        if(this.checked) {
            $('div[data-pdev-action="ws-different-shipping-show"]').show();
        }
        else {
            $('div[data-pdev-action="ws-different-shipping-show"]').hide();
        }
    });

    //Show / hide create account
    $('input[data-pdev-action="ws-create-account"]').off();
    $('input[data-pdev-action="ws-create-account"]').on('click', function()
    {
        if(this.checked) {
            $('div[data-pdev-action="ws-create-account-show"]').show();
        }
        else {
            $('div[data-pdev-action="ws-create-account-show"]').hide();
        }
    });

}
