/**
 * Check if store is open on selected date
 * @param date
 * @param shipping_method
 * @returns {Promise<unknown>}
 */
async function checkOpeningHours(date, shipping_method = 0) {
    //Get payment method if available
    if(shipping_method === 0) {
        shipping_method = getShippingMethod();
    }

    //Check opening hours
    return new Promise(async (resolve, reject) => {
        $.ajax
        ({
            type: "GET",
            dataType: 'json',
            url: front_OpeningHours_url,
            data: {option:'DateTimeLocalOpen', date:date, shipping_method:shipping_method},
            success: function(data)
            {
                resolve(data);
            }
        });
    });
}