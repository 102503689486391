/**
 * refresh cart content
 */
function cartContent(parameters = {})
{
    $( document ).ready(function() {
        return new Promise(async (resolve, reject) => {
            parameters.option = 'cartContent';
            $.ajax
            ({
                type: "POST",
                dataType: 'json',
                url: front_Webshop_shop_shoppingcart_url,
                data: parameters,
                success: function(data)
                {
                    $('*[data-pdev-shopping-cart-content]').replaceWith(data);
                    activateWebshopEvents();

                    //Check if response function exists and execute it
                    if(window.PDEV_ws_cart_content) {
                        PDEV_ws_cart_content();
                    }
                    resolve(data);
                }
            });
        })
    });
}